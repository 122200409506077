exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-neurosurgery-landing-js": () => import("./../../../src/pages/neurosurgery-landing.js" /* webpackChunkName: "component---src-pages-neurosurgery-landing-js" */),
  "component---src-pages-paincare-landing-js": () => import("./../../../src/pages/paincare-landing.js" /* webpackChunkName: "component---src-pages-paincare-landing-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

